export const CURRENT = 'current'
export const UPCOMING = 'upcoming'
export const COMPLETED = 'completed'
export const CANCELLED = 'cancelled'

const BookingStatus = {
  CURRENT,
  UPCOMING,
  COMPLETED,
  CANCELLED,
}

export default BookingStatus
