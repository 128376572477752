<template>
  <div>
    <v-app-bar flat color="transparent" class="main-appbar">
      <app-bar-nav-icon />
      <v-toolbar-title class="breadcrumbs d-flex align-center">
        <v-icon class="text-light-blue">{{ icons.arrowLeft }}</v-icon>
        <router-link class="text-decoration--none" :to="{ name: 'bookings' }"
          >Bookings</router-link
        >
      </v-toolbar-title>
      <v-spacer />
      <!-- <div>
        <v-btn
          color="primary"
          class="mr-4"
          medium
          text
          style="text-transform: none"
          >Cancel Booking</v-btn
        >
      </div> -->
    </v-app-bar>
    <v-row class="px-4 py-4" v-if="!loading">
      <v-col lg="8" md="6" sm="12" xs="12">
        <v-card outlined class="px-6 py-8">
          <v-card-title>Booking Information</v-card-title>
          <v-card-text>
            <v-row>
              <v-col md="6">
                <h4>Booking Status</h4>
              </v-col>
              <v-col md="6">
                <span :class="booking.status">{{
                  booking.status.toUpperCase()
                }}</span>
              </v-col>
            </v-row>
            <v-row v-if="booking.status === 'cancelled'">
              <v-col md="6">
                <h4>Cancelled At</h4>
              </v-col>
              <v-col md="6">
                <span :class="booking.status">{{ booking.cancelledAt }}</span>
              </v-col>
            </v-row>
            <v-row v-if="booking.status === 'cancelled'">
              <v-col md="6">
                <h4>Cancellation Details</h4>
              </v-col>
              <v-col md="6">
                <span class="text-capitalize" :class="booking.status">{{
                  formatCancellationCode(booking.cancelled_reason_code)
                }}</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="6">
                <h4>Booked Item</h4>
              </v-col>
              <v-col md="6">
                <router-link
                  :to="{
                    name: 'listing.edit',
                    params: { id: booking.listing.id },
                  }"
                  target="_blank"
                  >{{ booking.listing.title }}
                </router-link>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="6">
                <h4>Booked By</h4>
              </v-col>
              <v-col md="6">
                <router-link
                  :to="{
                    name: 'user.details',
                    params: { id: booking.user.id },
                  }"
                  target="_blank"
                  >{{ booking.user.full_name }}
                </router-link>
                <span v-if="booking.user.deleted_at" class="red--text"
                  >(Deleted Account)</span
                >
              </v-col>
            </v-row>
            <v-row>
              <v-col md="6">
                <h4>Booked Dates</h4>
              </v-col>
              <v-col md="6">
                <span>{{ booking.startDate + ' - ' + booking.endDate }}</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="6">
                <h4>Date Returned</h4>
              </v-col>
              <v-col md="6">
                <span>{{ booking.returnedDate }}</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="12">
                <v-btn
                  color="error"
                  outlined
                  class="mr-4 px-6"
                  height="40px"
                  width="100%"
                  @click="showDeleteDialog = true"
                  >Delete Booking</v-btn
                >
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col lg="4" md="6" sm="12">
        <v-card outlined class="px-6 py-8">
          <v-card-title>Payment Information</v-card-title>
          <v-card-text>
            <v-row>
              <v-col md="6">
                <h4>Item Price Per Day</h4>
              </v-col>
              <v-col md="6" class="text-right">
                <span>{{ getMoneyFormat(booking.price) }}</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="6">
                <h4>Bond</h4>
              </v-col>
              <v-col md="6" class="text-right">
                <span>{{ getMoneyFormat(booking.bond) }}</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="6">
                <h4>Days Booked</h4>
              </v-col>
              <v-col md="6" class="text-right">
                <span>{{ booking.days }}</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="6">
                <h4>Service Fee</h4>
              </v-col>
              <v-col md="6" class="text-right">
                <span>{{ getMoneyFormat(booking.service_fee) }}</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="6">
                <h4>Total Amount</h4>
              </v-col>
              <v-col md="6" class="text-right">
                <span>{{ getMoneyFormat(booking.total_amount) }}</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="6">
                <h4>Remaning Balance</h4>
              </v-col>
              <v-col md="6" class="text-right">
                <span>{{ getMoneyFormat(booking.remaining_balance) }}</span>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="px-4 py-4" v-if="!loading">
      <v-col lg="8" md="6" sm="12" xs="12">
        <v-card outlined class="px-6 py-8">
          <v-card-title>Late Return Charges</v-card-title>
          <v-simple-table dense>
            <thead>
              <tr>
                <th>Date Charged</th>
                <th class="text-right">Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="lateReturnPayment in lateReturnPaymentList"
                :key="lateReturnPayment.id"
              >
                <td>
                  <span> {{ formatDate(lateReturnPayment.created_at) }} </span>
                </td>
                <td class="text-right">
                  <span>{{ getMoneyFormat(lateReturnPayment.amount) }}</span>
                </td>
              </tr>

              <template v-if="loading">
                <tr v-for="index in 2" :key="index + '-skeleton'">
                  <td class="pl-0">
                    <v-skeleton-loader
                      type="list-item"
                      transition="fade-transition"
                    />
                  </td>
                  <td class="pl-0">
                    <v-skeleton-loader
                      type="list-item"
                      transition="fade-transition"
                    />
                  </td>
                </tr>
              </template>
            </tbody>
          </v-simple-table>
          <div
            class="text-center mt-2 mb-8"
            v-if="lateReturnPaymentListMeta.last_page > 1"
          >
            <v-pagination
              @input="pageChanged"
              :value="lateReturnPaymentListMeta.current_page"
              :length="lateReturnPaymentListMeta.last_page"
              :total-visible="10"
              circle
            ></v-pagination>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="px-4 py-4" v-if="!loading">
      <v-col lg="8" md="6" sm="12" xs="12" v-if="booking.reviews.length">
        <v-card>
          <v-card-title>Reviews</v-card-title>
          <v-card-text>
            <v-card
              outlined
              class="px-6 py-8 mt-5"
              v-for="(review, index) in booking.reviews"
              :key="index"
            >
              <v-card-text>
                <v-row justify="center" align="center">
                  <v-col md="6">
                    <h4>Review author</h4>
                  </v-col>
                  <v-col md="6">
                    <h4>
                      <router-link
                        :to="{
                          name: 'user.details',
                          params: { id: review.author.id },
                        }"
                        target="_blank"
                        >{{ review.author.full_name }}
                      </router-link>
                    </h4>
                  </v-col>
                  <v-col md="6">
                    <h4>Rating</h4>
                  </v-col>
                  <v-col md="6">
                    <v-rating
                      half-increments
                      v-model="review.rating"
                      background-color="orange lighten-3"
                      color="orange"
                      readonly
                    ></v-rating>
                  </v-col>
                </v-row>
                <v-row justify="center" align="center">
                  <v-col md="6">
                    <h4>Review Title</h4>
                  </v-col>
                  <v-col md="6">
                    <p>{{ review.title }}</p>
                  </v-col>
                </v-row>
                <v-row justify="center" align="center">
                  <v-col md="6">
                    <h4>Review Description</h4>
                  </v-col>
                  <v-col md="6">
                    <p>{{ review.description }}</p>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col lg="8" md="6" sm="12" xs="12" v-else>
        <v-card outlined class="px-6 py-8">
          <v-card-title>No Reviews Yet</v-card-title>
        </v-card>
      </v-col>
    </v-row>
    <v-overlay absolute :value="loading" opacity="0.2">
      <v-progress-circular
        indeterminate
        color="primary"
        size="64"
      ></v-progress-circular>
    </v-overlay>

    <confirm-modal
      v-model="showDeleteDialog"
      title="Delete Listing"
      message="Are you sure you want to delete this booking?"
      @cancel="showDeleteDialog = false"
      @confirm="deleteBooking"
      confirm-color="error"
    />

    <v-snackbar v-model="snackbar.show" right :color="snackbar.color">
      {{ snackbar.message }}
      <v-btn text @click="snackbar.show = false">OK</v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import { mdiChevronLeft } from '@mdi/js'
import { mapState, mapActions, mapMutations } from 'vuex'
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
import ConfirmModal from '@/components/modals/ConfirmModal'
import Currency from '@/utils/mixins/Currency'
import dayjs from 'dayjs'
import { get } from 'lodash'
import Snackbar from '@/utils/mixins/Snackbar'

export default {
  name: 'BookingDetailsPage',
  mixins: [Currency, Snackbar],
  data() {
    return {
      loading: false,
      showDeleteDialog: false,
      deleting: false,
      icons: {
        arrowLeft: mdiChevronLeft,
      },
    }
  },

  components: {
    AppBarNavIcon,
    ConfirmModal,
  },

  computed: {
    ...mapState({
      booking: (state) => state.booking.recordDetails,
      lateReturnPaymentListMeta: (state) =>
        state.booking.lateReturnPaymentListMeta,
      lateReturnPaymentList: (state) => state.booking.lateReturnPaymentList,
    }),
  },

  methods: {
    ...mapActions({
      getBookingDetails: 'booking/getRecordDetails',
      getBookingLateReturnPayments: 'booking/getBookingLateReturnPayments',
    }),

    ...mapMutations({
      clearBookingDetails: 'booking/clearRecordDetails',
    }),

    async getBooking() {
      this.loading = true
      await this.getBookingDetails(this.$route.params.id)
      this.loading = false
    },

    async getBookingLateReturnCharges(page = 1) {
      let params = { page }
      params.booking_id = this.$route.params.id
      await this.getBookingLateReturnPayments(params)
    },

    formatCancellationCode(cancellationCode) {
      return cancellationCode.replace(/_/g, ' ')
    },

    formatDate(date) {
      return dayjs.utc(date).local().format('MMM DD, YYYY')
    },

    pageChanged(page) {
      this.getBookingLateReturnCharges(page)
    },

    async deleteBooking() {
      this.deleting = true

      this.booking
        .delete()
        .then(() => {
          this.showMainSnackbar('Booking deleted successfully!', 'success')
          this.$router.replace('/bookings')
        })
        .catch((e) => {
          this.showSnackbar(get(e, 'response.data.message'), 'error')
        })
        .finally(() => {
          this.deleting = false
          this.showDeleteDialog = false
        })
    },
  },

  created() {
    this.getBooking()
    this.getBookingLateReturnCharges(1)
  },

  destroyed() {
    this.clearBookingDetails()
  },

  watch: {
    $route() {
      this.getBooking()
    },
  },
}
</script>

<style Scoped>
table > tbody > tr:hover td {
  background: #eeeeee !important;
  cursor: pointer;
}

.completed {
  color: #1b5e20;
}
.current {
  color: #01579b;
}
.upcoming {
  color: #00897b;
}
.cancelled {
  color: #d50000;
}
</style>
